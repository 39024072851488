<template>
  <generic-section comp-id="features" height="calc(var(--oh) * 619)" >
      <div class="features-container">
        <div class="features-phone-wrapper">
          <div class="loading-bar-outer">
            <div class="loading-bar-wrapper">
              <loading-bar :progress="progressPercent + 0.1666" :style="{ opacity: clamp( (1 - ratio * 0.1666 - 0.0624) * 15 + 1 ,0, 1)}" />
            </div>
          </div>
        </div>
      </div>
    <div class="features-phone-container">
      <div class="features-phone-wrapper" style="z-index: 11;">
        <div id="phone-page-slides">
          <feature-pages :active-testimonial="activeTestimonial" />
        </div>
        <img id="phone-frame-layer" src="../../../assets/phone-frame.png" />
      </div>
    </div>
    <feature-card-collection :progress="ratio * 0.1666" />
  </generic-section>
</template>

<script>
import { onMounted, ref, inject, onUnmounted } from "vue";
import clamp from "lodash.clamp";

import listenerHelper from "@/utils/helpers/listenerHelper";
import GenericSection from "@/components/generic/GenericSection";
import MobileFeatureCardCollection from "@/components/mobile/FeatureCardCollection";
import MobileLoadingBar from "@/components/mobile/LoadingBar";
import FeaturePages from "@/components/common/FeaturePages";
import animations from "@/utils/animations";

export default {
  name: "Features",
  components: {
    "feature-card-collection": MobileFeatureCardCollection,
    "generic-section": GenericSection,
    "loading-bar": MobileLoadingBar,
    "feature-pages": FeaturePages
  },
  props: {
    activeTestimonial: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const progressPercent = ref(0);
    const ratio = ref(0);
    const global = inject("global")
    let scrollPos = 0;
    const scrollListener = () => {

      const scrollTop = global.isBodyScrolling ? document.body.scrollTop : document.scrollingElement.scrollTop
      const outerHeight = parseFloat(document.documentElement.style.getPropertyValue("--oh")) * 100
      const height = parseFloat(document.documentElement.style.getPropertyValue("--oh")) * 100

      ratio.value = clamp((document.scrollingElement.scrollTop - (outerHeight * 3 )) / (height * 0.5), 0 , 1)
          + clamp((document.scrollingElement.scrollTop - (outerHeight * 3.5)) / height, 0, 6)
      progressPercent.value = clamp((document.scrollingElement.scrollTop - (outerHeight * 3)) / height, 0, 6) * 0.1666

      const phoneSlides = document.getElementById("feature-pages");
      const phoneSlidesHeight = phoneSlides.clientHeight / 7

      const translateRatio = clamp((document.scrollingElement.scrollTop - (outerHeight * 3)) / height, 0, 6)
      const translatePixel = Math.min(translateRatio * phoneSlidesHeight, phoneSlidesHeight * 6)

      phoneSlides.style.transform = `translateY(-${translatePixel}px)`

      const phoneFrame = document.getElementById("phone-frame-layer")
      const phoneSlidesContainer = document.getElementById("phone-page-slides")

      const topValue = 38 + clamp((scrollTop - (outerHeight * 8.2)) / height, 0, 1) * 18

      phoneFrame.style.top = topValue + "%"
      phoneSlidesContainer.style.top = (topValue + 0.32) + "%"

      if(scrollTop >= height && scrollPos < height) {
        const aboutHeadersContainer = document.getElementById("about-header-container")
        animations.appearUp(aboutHeadersContainer)
      } else if(scrollTop < height && scrollPos >= height) {
        const aboutHeadersContainer = document.getElementById("about-header-container")
        animations.disappearDown(aboutHeadersContainer)
      }

      if(scrollTop >= height * 9.10 && scrollPos < height * 9.10) {
        const testimonialsHeaderContainer = document.getElementById("testimonials-header")
        animations.appearUp(testimonialsHeaderContainer)
      } else if(scrollTop < height * 9.10 && scrollPos >= height * 9.10) {
        const testimonialsHeaderContainer = document.getElementById("testimonials-header")
        animations.disappearDown(testimonialsHeaderContainer)
      }

      if(scrollTop >= height * 9.29 && scrollPos < height * 9.29) {
        const blogHeaderContainer = document.getElementById("blog-header-container")
        animations.appearUp(blogHeaderContainer)

      } else if(scrollTop < height * 9.29 && scrollPos >= height * 9.29) {
        const blogHeaderContainer = document.getElementById("blog-header-container")
        animations.disappearDown(blogHeaderContainer)
      }
      scrollPos = scrollTop

    }
    onMounted(() => {
      if(global.isBodyScrolling) {
        document.body.addEventListener("scroll", scrollListener, listenerHelper.passiveIfSupported())
      } else {
        document.addEventListener("scroll", scrollListener, listenerHelper.passiveIfSupported())
      }
    })
    onUnmounted(() => {
      if(global.isBodyScrolling) {
        document.body.removeEventListener("scroll", scrollListener, listenerHelper.passiveIfSupported())
      } else {
        document.removeEventListener("scroll", scrollListener, listenerHelper.passiveIfSupported())
      }
    })
    return {
      progressPercent,
      ratio,
      clamp
    }
  }

}
</script>

<style scoped>
.features-container {
  position: absolute;
  width: 100%;
  height: calc(var(--oh) * 630);
  top: 0;
  left: 0;
}
.features-phone-container {
  position: absolute;
  width: 100%;
  height: calc(var(--oh) * 714);
  top: 0;
  left: 0;
  pointer-events: none;
}
.features-phone-wrapper {
  width: 100%;
  height: calc(var(--oh) * 100);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  transition: height 0.35s;
}
#phone-frame-layer {
  position: absolute;
  height: calc(var(--vh) * 75);
  width: calc(var(--vh) * 37.18);
  top: calc(var(--oh) * 25);
  transform: translate(-50%, -50%);
  transition: all 0.35s;
  z-index: 12;
  -webkit-touch-callout: none;
}
#phone-page-slides {
  position: absolute;
  overflow: hidden;
  left: 50%;
  z-index : 11;
  transform: translate(-50%, -50%);
  height: calc(var(--vh) * 72);
  width: calc(var(--vh) * 33.64);
  bottom: calc(var(--vh) * 31.28);
  transition: all 0.35s;
  border-radius: 20px;
}

.loading-bar-wrapper {
  position: relative;
  margin-left: 7.1vw;
  width: 85.8vw;
  height: calc(var(--oh) * 1);
  margin-top: calc(var(--oh) * 0.5);
}

.loading-bar-outer {
  position: absolute;
  bottom: 0;
  height: calc(var(--oh) * 7);
  width: 100vw;
  background-color: #ffffff;
  box-shadow: none;
}

</style>