<template>
  <div class="loading-bar-container">
    <div class="bar">
      <div class="bar-loaded" :style="{ width: clamp(progress / 0.1666, 0, 1) * 100 + '%'}" />
    </div>
    <div class="bar">
      <div class="bar-loaded" :style="{ width: clamp((progress - 0.1666) / 0.1666, 0, 1) * 100 + '%'}" />
    </div>
    <div class="bar">
      <div class="bar-loaded" :style="{ width: clamp((progress - 0.1666 * 2) / 0.1666, 0, 1) * 100 + '%'}" />
    </div>
    <div class="bar">
      <div class="bar-loaded" :style="{ width: clamp((progress - 0.1666 * 3) / 0.1666, 0, 1) * 100 + '%'}" />
    </div>
    <div class="bar">
      <div class="bar-loaded" :style="{ width: clamp((progress - 0.1666 * 4) / 0.1666, 0, 1) * 100 + '%'}" />
    </div>
    <div class="bar">
      <div class="bar-loaded" :style="{ width: clamp((progress - 0.1666 * 5) / 0.1666, 0, 1) * 100 + '%'}" />
    </div>
  </div>
</template>

<script>
import clamp from "lodash.clamp"
export default {
  name: "MobileLoadingBar",
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  setup() {
    return {
      clamp
    }
  }
}
</script>

<style scoped>
.loading-bar-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.bar {
  width: 16%;
  height: 100%;
  background: #EBEFFF;
  border-radius: 2.5px;
  overflow: hidden;
}

.bar-loaded {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #A5B4FF;
}


</style>