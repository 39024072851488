<template>
  <div class="feature-card-spacer" />
  <div class="feature-card-container" style="height: calc(var(--oh) * 50);">
    <div class="feature-card-wrapper" style="bottom: 0; height: calc(34% - 20px);">
      <feature-card
        label="FEATURE_SCAN_HEADER"
        content="FEATURE_SCAN_CONTENT"
        :active="true"
        icon="scan"
        :opacity="clamp(Math.pow(2.3 - Math.abs(0.07 - progress) * 15, 8) , 0, 1)"
        @card-click="$emit('active-card-change',0)"/>
    </div>
  </div>

  <div class="feature-card-container">
    <div class="feature-card-wrapper">
      <feature-card
        label="FEATURE_OCR_HEADER"
        content="FEATURE_OCR_CONTENT"
        :active="true"
        icon="ocr"
        :opacity="clamp( Math.pow(2.3 - Math.abs(0.2368 - progress) * 15, 8) , 0, 1)"
        @card-click="$emit('active-card-change',1)" />
    </div>
  </div>

  <div class="feature-card-container">
    <div class="feature-card-wrapper">
      <feature-card
        label="FEATURE_SIGNATURE_HEADER"
        content="FEATURE_SIGNATURE_CONTENT"
        :active="true"
        icon="signature"
        :opacity="clamp( Math.pow(2.3 - Math.abs(0.4034 - progress) * 15, 8) , 0, 1)"
        @card-click="$emit('active-card-change',2)" />
    </div>
  </div>

  <div class="feature-card-container">
    <div class="feature-card-wrapper">
      <feature-card
          label="FEATURE_EDIT_HEADER"
          content="FEATURE_EDIT_CONTENT"
          :active="true"
          icon="edit"
          :opacity="clamp(Math.pow( 2.3 - Math.abs(0.57 - progress) * 15, 8) , 0, 1)"
          @card-click="$emit('active-card-change',3)" />
    </div>
  </div>

  <div class="feature-card-container">
    <div class="feature-card-wrapper">
      <feature-card
          label="FEATURE_SHARE_HEADER"
          content="FEATURE_SHARE_CONTENT"
          :active="true"
          icon="share"
          :opacity="clamp( Math.pow(2.3 - Math.abs(0.7366 - progress) * 15, 8) , 0, 1)"
          @card-click="$emit('active-card-change',4)"/>
    </div>
  </div>

  <div class="feature-card-container">
    <div class="feature-card-wrapper">
      <feature-card
          label="FEATURE_TRANSLATE_HEADER"
          content="FEATURE_TRANSLATE_CONTENT"
          :active="true"
          icon="translate"
          :opacity="clamp( Math.pow(2.1 - Math.abs(0.9032 - progress) * 15, 8) , 0, 1)"
          :style="progress > 1 ? { opacity: 0}: {}"
          @card-click="$emit('active-card-change',5)"/>
    </div>
  </div>

</template>

<script>
import clamp from "lodash.clamp"
import FeatureCard from "@/components/common/FeatureCard";
export default {
name: "MobileFeatureCardCollection",
  components: {
    "feature-card": FeatureCard
  },
  props: {
    progress: {
      type: Number,
      default: 0
    }
  },
  setup() {
   return {
     clamp
   }
  }
}
</script>

<style scoped>
.feature-card-container {
  position: relative;
  height: calc(var(--oh) * 100);
  width: 85.8%;
}
.feature-card-spacer {
  height: calc(var(--oh) * 73);
}
.feature-card-wrapper {
  position: sticky;
  top: calc(var(--oh) * 74);
  height: calc(17% - 20px);
  width: calc(100%);
  box-sizing: border-box;
  margin: 10px 0;
  display: flex;
  justify-content: center;
}
</style>